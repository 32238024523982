import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../assets/svg/logo.svg';
import menu from '../assets/svg/menu.svg';

import list from '../assets/svg/list.svg';
import add from '../assets/svg/add.svg';
import rocket from '../assets/svg/rocket.svg';
import mail from '../assets/svg/mail.svg';
import settings from '../assets/svg/settings.svg';
import exit from '../assets/svg/exit.svg';

import home from '../assets/svg/home.svg';

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            _show: true
        };
    }




    render() {

        return (

            <div className={this.state._show ? `sidebar` : 'sidebar sidebar-hidden'}>
                <div className="top">
                    <div className="logo">
                        <img src={logo} />
                    </div>

                </div>

                <div className="items">
                    <h6>REZERVACIJE</h6>
                    <ul>

                        <li>
                            <Link to='/reservations' className={this.props[0].location.pathname == '/reservations' ? 'active' : null}>
                                <Isvg src={list} />
                                Pregled
                            </Link>
                        </li>

                        <li>
                            <Link to='/tables' className={this.props[0].location.pathname == '/tables' ? 'active' : null}>
                                <Isvg src={list} />
                                Stolovi
                            </Link>
                        </li>



                    </ul>

                    <h6>USLOVI</h6>
                    <ul>

                        <li>
                            <Link to='/collection/conditions' className={this.props[0].location.pathname == '/collection/conditions' ? 'active' : null}>
                                <Isvg src={list} />
                                Svi uslovi
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/conditions/new' className={this.props[0].location.pathname == '/collection/conditions/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj uslov
                            </Link>
                        </li>


                    </ul>


                    <h6>NOVOSTI</h6>
                    <ul>

                        <li>
                            <Link to='/collection/news' className={this.props[0].location.pathname == '/collection/news' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve novosti
                            </Link>
                        </li>


                        <li>
                            <Link to='/collection/news/new' className={this.props[0].location.pathname == '/collection/news/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj novost
                            </Link>
                        </li>


                    </ul>

                    { /*
                    <h6>ZAPOSLENJE</h6>
                    <ul>
                        <li>
                            <Link to='/collection/employments' className={this.props[0].location.pathname == '/collection/employments' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve stranice
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/employments/new' className={this.props[0].location.pathname == '/collection/employments/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj stranicu
                            </Link>
                        </li>

                    </ul>
                    */
                    }
                    {/*
                    <h6>KATEGORIJE</h6>
                    <ul>
                        <li>
                            <Link to='/collection/categories' className={this.props[0].location.pathname == '/collection/categories' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve kategorije
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/categories/new' className={this.props[0].location.pathname == '/collection/categories/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj kategoriju
                            </Link>
                        </li>

                    </ul>
*/}
                    <h6>GALERIJA</h6>
                    <ul>
                        <li>
                            <Link to='/collection/gallery' className={this.props[0].location.pathname == '/collection/gallery' ? 'active' : null}>
                                <Isvg src={list} />
                                Sve galerije
                            </Link>
                        </li>
                        <li>
                            <Link to='/collection/gallery/new' className={this.props[0].location.pathname == '/collection/gallery/new' ? 'active' : null}>
                                <Isvg src={add} />
                                Dodaj galeriju
                            </Link>
                        </li>

                    </ul>

                    <h6>Muzika</h6>
                    <ul>
                        <li>
                            <Link to='/audio' className={this.props[0].location.pathname == '/audio' ? 'active' : null}>
                                <Isvg src={list} />
                                Muzika u pozadini
                            </Link>
                        </li>

                    </ul>


                    {/*
                    <h6>KONTAKTI</h6>
                    <ul>
                        <li>
                            <Link to='/contacts' className={this.props[0].location.pathname == '/contacts' ? 'active' : null}>
                                <Isvg src={mail} />
                                Svi kontakti
                            </Link>
                        </li>

                    </ul>
                    <h6>ZAPOSLENJE</h6>
                    <ul>
                        <li>
                            <Link to='/competitions' className={this.props[0].location.pathname == '/competitions' ? 'active' : null}>
                                <Isvg src={mail} />
                                Sve prijave
                            </Link>
                        </li>

                    </ul>*/}

                    {/*
                    <h6>PODEŠAVANJA</h6>
                    <ul>
                        <li>
                            <Link to='/config' className={this.props[0].location.pathname == '/config' ? 'active' : null}>
                                <Isvg src={settings} />
                                Informacije na sajtu
                            </Link>
                        </li>
                        <li>
                            <Link to='/seo' className={this.props[0].location.pathname == '/seo' ? 'active' : null}>
                                <Isvg src={rocket} />
                                SEO tagovi
                            </Link>
                        </li>
                    </ul>
*/}
                    <ul className="logout">
                        <li onClick={() => localStorage.removeItem('token')}>
                            <Link to='/login' >
                                <Isvg src={exit} />
                                Odjavi se
                            </Link>
                        </li>
                    </ul>

                </div>

                <div className="menu" onClick={() => this.setState({ _show: !this.state._show })}>
                    <Isvg src={menu} />
                </div>

            </div>
        )
    }

};

export default Sidebar;