
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import image from '../../../assets/svg/audio.svg';
import { Editor } from '@tinymce/tinymce-react';

class Image extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        let input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = async (e) => {
                this.setState({
                    _loading: true
                })
                fetch('https://sunrise.novamedia.agency/admin/upload', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`

                    },
                    body: JSON.stringify({ image: e.target.result })
                }).then((res) => res.text()).then((img) => {
                    this.props.onChange({
                        url: img,
                        name: input.files[0].name
                    });
                    this.setState({
                        _loading: null
                    })
                });

            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    render() {
        return (
            <div className="image-picker single-image-picker">
                <input type="file" onChange={this.selectFile} />
                <div className="no-image audio-image">
                    <Isvg src={image} />
                    <span className="text">{this.props.value ? this.props.value.name : 'Izaberite .mp3'} </span>
                    {
                        this.state._loading ?
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                            :
                            null
                    }
                    
                </div>

            </div>


        );
    }
}

export default Image;