import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';

import Text from './fields/text';
import Textarea from './fields/textarea';
import HtmlImage from './fields/htmlImage';
import Image from './fields/image';
import Html from './fields/html';
import Select from './fields/select';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    lang,
    multilang
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )
    const renderTextareaField = ({
        input,
        placeholder,
        label,
        meta: { touched, error },
        lang,
        multilang
    
    }) => (
    
            <Textarea
                placeholder={placeholder}
                label={label}
                errorText={touched && error}
                error={touched && error}
                multilang={multilang}
                lang={lang}
    
                {...input}
            />
        )
    
const renderHtmlField = ({
    input,
    placeholder,
    meta: { touched, error },
    lang,
    multilang

}) => (

        <Html
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            multilang={multilang}
            lang={lang}

            {...input}
        />
    )
const renderImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Image
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            additionalAction={additionalAction}
            scope={scope}
            {...input}
            children={children}
        />
    )



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col lg="12" >
                    <Container fluid className="form-box">
                        <Row>
                            <Col lg="12">
                                <h3 className="title">Novost</h3>
                                <h6 className="subtitle">Unesite informacije</h6>

                            </Col>
                            <Col lg="9">
                                <Container>
                                    <Row>


                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="name"
                                                component={renderTextField}
                                                label={"Naslov"}
                                                placeholder="Unesite naslov vijesti"
                                                lang={props.lang}
                                            ></Field>


                                        </Col>

                                        <Col lg="12" className="input-wrap">
                                            <Field
                                                name="content"
                                                component={renderTextareaField}
                                                label={"Tekst"}
                                                placeholder="Unesite tekst vijesti"
                                                lang={props.lang}

                                            ></Field>

                                        </Col>

                                    </Row>
                                </Container>
                            </Col>

                            <Col lg="3" className="input-wrap">

                                <Field
                                    name="image"
                                    component={renderImageField}

                                ></Field>
                            </Col>


                        </Row>
                    </Container>
                </Col>


                <Col lg="12">
                    <button className="button">Save</button>

                </Col>

            </Row>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
