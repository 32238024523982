import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import { GoogleMapScript } from './components/googleMapScript';
import LoginPage from './views/loginPage';
import PagesListPage from './views/pagesListPage';
import AddPagePage from './views/addPagePage';
import HomePage from './views/homePage';
import Tables from './views/tables';
import ReservationsPage from './views/reservationsPage';
import AudioPage from './views/audioPage';


class Routes extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <Router >
                <div>

                    <GoogleMapScript API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />

                    <Switch className="react-switch">
                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/audio"
                            exact
                            render={(...renderProps) => (
                                <AudioPage {...renderProps} {...this.props} />
                            )}
                        />


                                                        <Route
                            path="/tables"
                            exact
                            render={(...renderProps) => (
                                <Tables {...renderProps} {...this.props} />
                            )}
                        />

                                                        <Route
                            path="/reservations"
                            exact
                            render={(...renderProps) => (
                                <ReservationsPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/collection/:collection"
                            exact
                            render={(...renderProps) => (
                                <PagesListPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/collection/:collection/:id"
                            exact
                            render={(...renderProps) => (
                                <AddPagePage {...renderProps} {...this.props} />
                            )}
                        />



                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;